import "./styles.css";
import img1 from "../../images/tech-travel/train1-optimize.png";

import img2 from "../../images/albania/albania4-optimize.png";
import img5 from "../../images/tech-travel/tech-optimize.png";
import img4 from "../../images/albania/albania6-optimize.png";
import img3 from "../../images/albania/albania8-optimize.png";

const urlBois = [
  {
    url: img1,
  },
  {
    url: img2,
  },
  {
    url: img3,
  },
  {
    url: img4,
  },
  {
    url: img5,
  },
];

export default urlBois;
